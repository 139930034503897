import moment from "moment"

import {
  getFechaCreacionObj as getFechaCreacionObj_,
  getFechaCreacionActualizacionObj as getFechaCreacionActualizacionObj_,
  getFechaActualizacionObj as getFechaActualizacionObj_,
  getFechaObj as getFechaObj_,
  getBasicSortDesc as getBasicSortDesc_,
  getBasicSortAsc as getBasicSortAsc_,
  getRandomItemsUnicos as getRandomItemsUnicos_,
  getFichaTruperUrl as getFichaTruperUrl_,
  getTruperThumbnailImageByCodigo as getTruperThumbnailImageByCodigo_,
  getTruperImageByClave as getTruperImageByClave_,
  delay as delay_,
  fixUrl as fixUrl_,
  removeAttributes as removeAttributes_,
  getAttributes as getAttributes_,
  getDescripcion as getDescripcion_,
  precioMoneda as precioMoneda_,
  numberFormat as numberFormat_,
  firstAttributes as firstAttributes_,
  capitalizeFirstLetter as capitalizeFirstLetter_,
  getAntiguedad as getAntiguedad_,
  getNestedProperty as getNestedProperty_,
} from "acciondigital-commons/commonUtils"

export const getBasicSortDesc = getBasicSortDesc_

export const getBasicSortAsc = getBasicSortAsc_

export const getRandomItemsUnicos = getRandomItemsUnicos_

export const getFichaTruperUrl = getFichaTruperUrl_

export const getTruperThumbnailImageByCodigo = getTruperThumbnailImageByCodigo_

export const getTruperImageByClave = getTruperImageByClave_

export const getNestedProperty = getNestedProperty_
/* 
export function getNestedProperty(path, obj, separator = ".") {
  const properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((prev, curr) => prev?.[curr], obj)
}
*/

export const delay = delay_

export const fixUrl = fixUrl_

export const removeAttributes = removeAttributes_

export const getAttributes = getAttributes_

export const getFechaCreacionObj = getFechaCreacionObj_

export const getFechaCreacionActualizacionObj = getFechaCreacionActualizacionObj_

export const getFechaActualizacionObj = getFechaActualizacionObj_

export const getFechaObj = getFechaObj_

export const getAntiguedad = getAntiguedad_

export const getDescripcion = getDescripcion_

export const precioMoneda = precioMoneda_

export const numberFormat = numberFormat_

export const firstAttributes = firstAttributes_

export const capitalizeFirstLetter = capitalizeFirstLetter_
