import React from "react"
import "firebase/auth"
import "firebase/database"
import "bootswatch/dist/sandstone/bootstrap.min.css"
// import "bootswatch/dist/simplex/bootstrap.min.css"
// import "./src/css/custom.css"

import "./src/css/global.css"
// import { shouldUpdateScrollFn, wrapPageElementQueryProvider } from "acciondigital-basic-theme/src/utils/browser_ssr_utils"
import { shouldUpdateScrollFn } from "acciondigital-basic-theme/src/utils/gatsbyBrowserUtils"
import { wrapPageElementQueryProviderBase as wrapPageElementQueryProvider } from "acciondigital-basic-theme/src/utils/wrapPageElementQueryProviderBase"
import RootElement from "./src/components/rootElement"

export const shouldUpdateScroll = shouldUpdateScrollFn

export const wrapPageElement = wrapPageElementQueryProvider

export const wrapRootElement = ({ element }) => (
  <RootElement>{element}</RootElement>
)